// Generated by Framer (716dd6f)

import { addFonts, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {l3b_YAFiQ: {hover: true}};

const cycleOrder = ["l3b_YAFiQ"];

const variantClassNames = {l3b_YAFiQ: "framer-v-1x6z9t6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "l3b_YAFiQ", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "l3b_YAFiQ", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-HfevE", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-1x6z9t6", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"l3b_YAFiQ"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"l3b_YAFiQ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "sona-visual-multiplication-cards", fit: "fit", intrinsicHeight: 935, intrinsicWidth: 1206, pixelHeight: 935, pixelWidth: 1206, src: new URL("assets/tpbzgSzeLiieox4EQW3skOTwk1w.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/tpbzgSzeLiieox4EQW3skOTwk1w.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/tpbzgSzeLiieox4EQW3skOTwk1w.jpg", import.meta.url).href} 1024w, ${new URL("assets/tpbzgSzeLiieox4EQW3skOTwk1w.jpg", import.meta.url).href} 1206w`}} className={"framer-darswx"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"XkIwUhB8E"} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)"}} transition={transition} variants={{"l3b_YAFiQ-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-HfevE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-HfevE * { box-sizing: border-box; }", ".framer-HfevE .framer-1sm9wlb { display: block; }", ".framer-HfevE .framer-1x6z9t6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1140px; }", ".framer-HfevE .framer-darswx { aspect-ratio: 1.2949640287769784 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 834px); overflow: hidden; position: relative; width: 1px; }", ".framer-HfevE .framer-v-1x6z9t6 .framer-1x6z9t6 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-HfevE .framer-1x6z9t6 { gap: 0px; } .framer-HfevE .framer-1x6z9t6 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-HfevE .framer-1x6z9t6 > :first-child { margin-left: 0px; } .framer-HfevE .framer-1x6z9t6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 894
 * @framerIntrinsicWidth 1140
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"b7k03WUOa":{"layout":["fixed","auto"]}}}
 */
const FramertqnbnakNA: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramertqnbnakNA;

FramertqnbnakNA.displayName = "Choice Ai VE Hero image";

FramertqnbnakNA.defaultProps = {height: 894, width: 1140};

addFonts(FramertqnbnakNA, [])